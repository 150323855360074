import { defaultProps } from './types';

import type { IconType } from './types';

export const GooglePlay = ({
  className,
  width = 24,
  height = 24,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3.609 1.814L13.792 12L3.61 22.186C3.42894 22.1099 3.27443 21.9819 3.16589 21.8182C3.05736 21.6545 2.99964 21.4624 3 21.266V2.734C3.00008 2.53782 3.05786 2.346 3.16614 2.18242C3.27443 2.01883 3.42844 1.89071 3.609 1.814ZM14.499 12.707L16.801 15.009L5.864 21.342L14.499 12.707ZM17.698 9.509L20.505 11.135C20.6565 11.2229 20.7823 11.3491 20.8698 11.5009C20.9572 11.6527 21.0032 11.8248 21.0032 12C21.0032 12.1752 20.9572 12.3473 20.8698 12.4991C20.7823 12.6509 20.6565 12.7771 20.505 12.865L17.697 14.491L15.206 12L17.698 9.509ZM5.864 2.658L16.802 8.99L14.499 11.293L5.864 2.658Z"
      fill={color}
    />
  </svg>
);
