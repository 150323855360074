import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono GooglePayDark
 */
export const GooglePayDark = ({
  className,
  width = 32,
  height = 32,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    viewBox="0 0 32 32"
  >
    <path
      fill={color}
      d="m32 13.33-4.18 9.34h-1.29l1.55-3.27-2.75-6.07h1.36l2 4.65h.02l1.92-4.65zm-17.35 2.89V20h-1.32v-9.33h3.48a3.3 3.3 0 0 1 2.25.8c.61.5.95 1.22.94 1.98a2.5 2.5 0 0 1-.94 1.98c-.6.53-1.36.79-2.25.79zm0-4.4v3.25h2.19c.48.02.96-.16 1.3-.48.33-.3.52-.71.52-1.14 0-.43-.19-.84-.52-1.14a1.8 1.8 0 0 0-1.3-.5zm7.98 1.51c.83 0 1.5.24 1.98.7s.72 1.1.72 1.9v3.87h-1.08v-.87h-.05A2.14 2.14 0 0 1 22.34 20c-.67 0-1.22-.2-1.67-.62a2 2 0 0 1-.67-1.53c0-.64.23-1.16.7-1.54.48-.38 1.1-.58 1.9-.58.66 0 1.22.13 1.65.39v-.27c0-.4-.17-.78-.47-1.04-.3-.28-.68-.44-1.09-.43-.63 0-1.13.27-1.5.83l-1-.65a2.74 2.74 0 0 1 2.44-1.23zm-1.46 4.55c0 .3.13.58.37.77.25.2.57.3.88.3.48 0 .93-.2 1.27-.54.36-.34.57-.8.57-1.3-.36-.29-.85-.43-1.48-.43-.46 0-.84.11-1.15.34-.3.23-.46.52-.46.86zM5.44 10.67a5.3 5.3 0 0 1 3.64 1.39l-1.55 1.52a2.99 2.99 0 0 0-2.09-.8c-1.38 0-2.6.9-3.05 2.2a3.12 3.12 0 0 0 0 2.04 3.25 3.25 0 0 0 3.05 2.2c.74 0 1.37-.18 1.86-.5.56-.38.96-.96 1.08-1.63H5.44v-2.05h5.13c.06.36.1.72.1 1.08 0 1.63-.6 3-1.62 3.93a5.27 5.27 0 0 1-3.6 1.28A5.45 5.45 0 0 1 .57 18.4a5.25 5.25 0 0 1 0-4.8 5.45 5.45 0 0 1 4.86-2.93z"
    />
  </svg>
);
