import { defaultProps } from './types';

import type { IconType } from './types';

export const AppleStore = ({
  className,
  width = 24,
  height = 24,
  color = defaultProps.color,
}: IconType) => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.6239 7.22201C10.7479 7.22201 9.39194 6.22601 7.96394 6.26201C6.07994 6.28601 4.35194 7.35401 3.37994 9.04601C1.42394 12.442 2.87594 17.458 4.78394 20.218C5.71994 21.562 6.82394 23.074 8.28794 23.026C9.69194 22.966 10.2199 22.114 11.9239 22.114C13.6159 22.114 14.0959 23.026 15.5839 22.99C17.0959 22.966 18.0559 21.622 18.9799 20.266C20.0479 18.706 20.4919 17.194 20.5159 17.11C20.4799 17.098 17.5759 15.982 17.5399 12.622C17.5159 9.814 19.8319 8.47001 19.9399 8.41001C18.6199 6.47801 16.5919 6.26201 15.8839 6.21401C14.0359 6.07001 12.4879 7.22201 11.6239 7.22201ZM14.7439 4.39001C15.5239 3.45401 16.0399 2.14601 15.8959 0.850006C14.7799 0.898006 13.4359 1.59401 12.6319 2.53001C11.9119 3.35801 11.2879 4.69001 11.4559 5.96201C12.6919 6.05801 13.9639 5.32601 14.7439 4.39001Z"
      fill={color}
    />
  </svg>
);
